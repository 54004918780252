"use client";

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    <html>
      <body className="d-flex flex-column justify-content-center align-items-center vh-100 text-center">
      <div className="container">
        <h1 className="display-4 text-danger">¡Ups! Something went wrong</h1>
        <h4 className="mb-3">GLOBAL ERROR</h4>
        <p className="lead">Try refreshing the page.</p>
        <div className="mt-4">
          <button className="btn btn-primary-outline me-2" onClick={() => reset()}> Refresh</button>
          <a href="/" className="btn-primary-outline ">Let&apos;s go to Home Page</a>
      </div>
      </div>
      </body>
    </html>
  );
}
